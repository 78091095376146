import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ProfileCard from "../components/profile-card/profile-card"
import ServicesList from "../components/services-list/services-list"
import BlogList from "../components/blog-list/blog-list"

import "../styles/daivajnas.scss"

const DaivajnasPage = () => {
  const imageDaivajnas = useStaticQuery(graphql`
    query {
      imageKaimukku: file(
        relativePath: { eq: "Kaimukku_Raman_Akkithirippadu.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageCheruvally: file(
        relativePath: { eq: "Cheruvalli_Narayanan_Namboodiri.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageKaimukkuNarayanan: file(
        relativePath: { eq: "Kaimukku_Narayanan_Namboothiri.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageAchuthan: file(relativePath: { eq: "Achuthan_Nair.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagePadmanabha: file(relativePath: { eq: "Padmanabha_Sharma.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageAmbalakkoth: file(
        relativePath: { eq: "Ambalakkoth_Vijayaraghava_Panicker.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageKapali: file(relativePath: { eq: "Kapali_Namboothiri.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagePuliyath: file(
        relativePath: { eq: "Puliyath_Unnikrishnan_Gupthan.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageKunnamkulam: file(
        relativePath: { eq: "Kunnamkulam_Rajagopal.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagePuthuvamana: file(
        relativePath: { eq: "Puthuvamana_Hari_Namboodiri.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThamarassery: file(
        relativePath: { eq: "Thamarassery_Vinod_Panicker.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageDevidasan: file(relativePath: { eq: "Devidasan_Gurukkal.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageMattom: file(
        relativePath: { eq: "Mattom_Jayakrishnan_Panicker.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageMannarkkadu: file(
        relativePath: { eq: "Mannarkkadu_Subhramanyan.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageMannarkkadu: file(
        relativePath: { eq: "Mannarkkadu_Subhramanyan.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageKoottanadu: file(
        relativePath: { eq: "Koottanadu_Ravunni_Panicker.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThrikkunnathupuzha: file(
        relativePath: { eq: "Thrikkunnathupuzha_Udayakumar.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageVenugopal: file(relativePath: { eq: "Venugopal._P._N.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagePradeep: file(relativePath: { eq: "Pradeep_Athimattom.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const daivajnas = [
    {
      name: "The late Kaimukku Raman Akkithirippadu",
      description:
        "Kaimukku Raman Akkithirippadu, my guru was one of the top 3 Astrologers in Kerala until his death in 2021. He was the Chief Daivajna of Devaprasnams held at Chottanikkara Devi temple, Tripurnithura Sree Poornathrayeesha temple, Kottarakkara Ganapati temple, Sabarimala and Guruvaayoor. He was the discipline of the legendary astrologer Shri. K. M. Parameswaran, who was also his brother. He has assisted his brother in more than 500 Ashtamangalaprasnas and has been Chief of more than 500 Ashtamangalaprasnams. He has been part of Yajnas like Somayagam, Athiraathram etc as its Yajamana- the chief.",
      image: imageDaivajnas.imageKaimukku,
      number: false,
    },
    {
      name: "Achuthan Nair",
      description:
        "Achuthan Nair was initiated into Astrology by the famous Astrologers of Kaimukku Mana (Kaimukku Narayanan Namboothiri, Raman Akkithiripad, Kaippally Narayanan Namboothiri etc.). He is a Graduate in Mathematics and has completed C.A Inter as well employed in Kerala Govt Service for some time. He relinquished his job to take up full-time practice in Astrology. Endowed with a deep sense of dedication and sincerity in his profession he can give proper guidance and solutions for ever so many harassing problems confronting many of us in our hectic modern life. He was primary Daivajna of Devaprasnams held at Dattatreya temple, Chottanikkara, Thiruvairanikkulam etc.",
      image: imageDaivajnas.imageAchuthan,
      number: "+91 94977 22125",
    },
    {
      name: "Cheruvalli Narayanan Namboodiri",
      description:
        "Cheruvalli Narayanan Namboodiri was born at Paiyyannoor and currently resides at Arakkinar, Kozhikode. He was the discipline of Shri. C. P. Kunjirama Panicker and Brahmashree Y. Y. Kapali Namboodiri. Cheruvalli was chief Daivajna of 2015 Sabarimala Devaprasnam. He has been awarded Daivajna Siromani by Edakkad Astrological Research centre in 2006. He's also an expert in Vasthusasthra.",
      image: imageDaivajnas.imageCheruvally,
      number: "+91 95677 52287",
    },
    {
      name: "Kaimukku Narayanan Namboothiri",
      description:
        "Kaimukku Narayanan Namboothiri is the son of the Legend Kaimukku Vasudevan Namboothiri. He is running an astrology school near his mana and has imparted astrological wisdom to thousands of students. He is also the guru of well known Devaprasna Acharyas like Astrologer Pradeep Athimattom and Astrologer Achuthan Nair.",
      image: imageDaivajnas.imageKaimukkuNarayanan,
      number: "+91 944 687 2447",
    },
    {
      name: "Padmanabha Sharma",
      description:
        "Padmanabha Sharma is a well-known astrologer. He has been chief of Devaprasnams conducted in 2009 at Guruvayoor, in 2006 at Sabarimala and in 2015 at Kodungalloor.",
      image: imageDaivajnas.imagePadmanabha,
      number: "+91 854 788 1353",
    },
    {
      name: "Ambalakkoth Vijayaraghava Panicker",
      description:
        "Ambalakkoth Vijayaraghava Panicker is an eminent astrologer and resides near Kozhikode medical college. He's a familiar face in devaprasnams.",
      image: imageDaivajnas.imageAmbalakkoth,
      number: "+91 944 784 8433",
    },
    {
      name: "Kapali Namboothiri",
      description:
        "Kapali Namboothiri is a famous astrologer and teaches so many disciples like Cheruvally Narayanan Namboothiri and is the student of Krishnan Nair. His Ashtamangalaprasnas are known for its length and it takes one to two weeks for the prasnas to finish.",
      image: imageDaivajnas.imageKapali,
      number: false,
    },
    {
      name: "Puliyath Unnikrishnan Gupthan",
      description:
        "Puliyath Unnikrishnan Gupthan belongs to the twelfth generation of a family of astrologers. From the age of sixteen, he has participated in Devaprasnams with his uncle Chethalloor Krishnankutty Guptan.",
      image: imageDaivajnas.imagePuliyath,
    },
    {
      name: "Kunnamkulam Rajagopal",
      description:
        "Kunnamkulam Rajagopal is the student of the legendary Chethallur Krishnankutty Gupthan. He has participated in thousands of Devaprasnams beside his guru and as primary Daivajna.",
      image: imageDaivajnas.imageKunnamkulam,
    },
    {
      name: "Thamarassery Vinod Panicker",
      description:
        "Thamarassery Vinod Panicker is one of the prominent young Daivajnas in Kerala, he's a familiar face in Devaprasnams conduced at Wayanad and Bangalore.",
      image: imageDaivajnas.imageThamarassery,
      number: "+91 944 742 3669",
    },
    {
      name: "Devidasan Gurukkal",
      description:
        "Devidasan Gurukkal hails from Kannur and was part of Devaprasnams conducted at prominent temples like Guruvayor, Padhmanabha Swamy temple and many other devasom temples.",
      image: imageDaivajnas.imageDevidasan,
      number: "+91 974 794 9429",
    },
    {
      name: "Mannarkkadu Subhramanyan",
      description:
        "Mannarkkadu Subhramanyan is a familiar face in Devaprasnams conducted in Mannarkkadu and its surroundings. He is an eighth generation astrologer.",
      image: imageDaivajnas.imageMannarkkadu,
    },
    {
      name: "Puthuvamana Hari Namboodiri",
      description:
        "Puthuvamana Hari Namboodiri is a famous astrologer, a discipline of Padhmanabha Sharma. He was the chief of 2015 Guruvayoor Devaprasnam.",
      image: imageDaivajnas.imagePuthuvamana,
      number: "+91 944 737 0636",
    },
    {
      name: "Mattom Jayakrishnan Panicker",
      description:
        "Mattom Jayakumar Panicker hails from Guruvayoor. He has been part of more than 500 Ashtamangalaprasnams as chief including Guruvayoor Devaprasna.",
      image: imageDaivajnas.imageMattom,
      number: "+91 996 193 6699",
    },
    {
      name: "Koottanadu Ravunni Panicker",
      description:
        "Koottanadu Ravunni Panicker is one of the senior most Daivajna and he is the only Daivajna who finishes Devaprasnam in a single day.",
      image: imageDaivajnas.imageKoottanadu,
      number: "+91 984 635 9430",
    },
    {
      name: "Thrikkunnathupuzha Udayakumar",
      description:
        "Thrikkunnathupuzha Udayakumar has conducted about 300 Devaprasnams.",
      image: imageDaivajnas.imageThrikkunnathupuzha,
    },
    {
      name: "Venugopal. P. N",
      description:
        "Venugopal. P. N is a well-known astrologer who hails from Mala. He is a discipline of Kaimukku Raman Akkithirippadu and has attended more than 500 Ashtamangalaprasnams.",
      image: imageDaivajnas.imageVenugopal,
    },
    {
      name: "Pradeep Athimattom",
      description:
        "Pradeep Athimattom has conducted many Ashtamangalaprasnams and he is a discipline of Kaimukku Narayanan Namboodiri.",
      image: imageDaivajnas.imagePradeep,
      number: "+91 944 689 7909",
    },
  ]

  return (
    <Layout>
      <SEO
        title="Great daivajnas in the field of Ashtamangalaprasnam/ Devaprasnam"
        description="Information and contact info of Top 20 Daivajnas in Kerala."
        keyword="Daivajnas, ashtamangalaprasnam, devaprasnam, swarnaprasnam, contact details, mobile number, Cheruvalli Narayanan Namboodiri, Achuthan Nair, Kaimukku Narayanan Namboothiri, Padmanabha Sharma, Ambalakkoth Vijayaraghava Panicker, Kapali Namboothiri, Puliyath Unnikrishnan Gupthan, Kunnamkulam Rajagopal, Thamarassery Vinod Panicker, Devidasan Gurukkal, Mannarkkadu Subhramanyan, Puthuvamana Hari Namboodiri, Mattom Jayakrishnan Panicker, Koottanadu Ravunni Panicker, Thrikkunnathupuzha Udayakumar, Venugopal. P. N, Pradeep Athimattom, Kaimukku Raman Akkithirippadu, Chethalloor Vijayakumar Guptan, Kaladharan Master"
      />
      <div className="main-sub-wrapper">
        <div className="header-wrap">
          <h1 className="header__title h1_decorative">Daivajnas</h1>
          <p className="header__text">
            We've brought together the Great men in the field of
            Ashtamangalaprasnam/ Devaprasnam so that you could gain access to
            the best.
          </p>
        </div>
        <ul className="listing-wrap">
          {daivajnas.map(({ name, description, image, number }) => (
            <ProfileCard
              key={name.replace(/\s/g, "")}
              name={name}
              description={description}
              image={image}
              number={number}
            ></ProfileCard>
          ))}
        </ul>
        <ServicesList cssClass="daivajna" />
        <BlogList cssClass="daivajna" />
      </div>
    </Layout>
  )
}

export default DaivajnasPage
