import PropTypes from "prop-types"
import React, { useState } from "react"
import Image from "gatsby-image"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"

import "./profile-card.scss"

import Namaste from "../../images/icons/hinduism.svg"
import Close from "../../images/icons/close.svg"
import Phone from "../../images/icons/phone.svg"

const ProfileCard = props => {
  const [formShown, setFormShown] = useState(false)
  const toggleFormShown = () => {
    setFormShown(!formShown)
    setSubmissionStatus("")
  }

  const [submissionStatus, setSubmissionStatus] = useState("")
  const handleSubmissionStatus = axiosStatus => {
    setSubmissionStatus(axiosStatus)
  }

  const successMessageClose = () => {
    setFormShown(!formShown)
    setSubmissionStatus("")
  }

  return (
    <li className="profile-card" key={props.name.replace(/\s/g, "")}>
      <div className="profile-card__image-wrap">
        <Image
          className="profile-card__image"
          fluid={props.image.childImageSharp.fluid}
          alt={props.name}
          draggable={false}
        />
      </div>
      <div className="profile-card__info-wrap">
        <div className="profile-card__content-wrap">
          <h2 className="h4 profile-card__title">{props.name}</h2>
          <p className="profile-card__description">{props.description}</p>
        </div>
        <div className="button-wrap profile-card__button-wrap">
          {/* <button className="button" onClick={toggleFormShown}>
            Message
          </button> */}
          {props.number ? (
            <a
              className="button_contact"
              href={"tel:" + props.number}
              title={"Click to call " + props.name + " at " + props.number}
            >
              <img src={Phone} alt="" className="button_contact__icon"></img>
              {props.number.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})/,
                "$1-$2-$3-$4"
              )}
            </a>
          ) : (
            ""
          )}
        </div>
        {formShown === true ? (
          <div className="profile-card__form-wrap">
            <Formik
              initialValues={{
                name: "",
                contactInfo: "",
                daivajna: props.name,
                message: "",
              }}
              validate={values => {
                const errors = {}
                if (!values.name) {
                  errors.name = "Please enter you name"
                }
                if (!values.contactInfo) {
                  errors.contactInfo =
                    "Please enter you e-mail or phone number so that we could contact you"
                } else if (
                  !(
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.contactInfo
                    ) || /^(\+\d{1,3}[- ]?)?\d{10}$/i.test(values.contactInfo)
                  )
                ) {
                  errors.contactInfo = "Invalid email or number"
                }
                if (!values.message) {
                  errors.message =
                    "Please enter the message so that we could help you"
                }
                if (values.policy === true) {
                  errors.policy = "Please check to proceed"
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false)
                axios
                  .post("https://submit-form.com/rnCzlXIs", values)
                  .then(res => {
                    handleSubmissionStatus("success")
                  })
                  .catch(err => {
                    handleSubmissionStatus("failure")
                    console.log(err)
                  })
              }}
            >
              {({ isSubmitting }) => (
                <Form className="form profile-card__form">
                  {submissionStatus === "failure" ? (
                    <p className="form__main-error">
                      Sorry, please send again, the message wasn't sent.
                    </p>
                  ) : (
                    ""
                  )}

                  {submissionStatus === "success" ? (
                    <div className="form-success">
                      <button
                        aria-label="Close"
                        className="button form-success__close"
                        onClick={successMessageClose}
                      >
                        <img
                          src={Close}
                          alt="close"
                          className="form-success__close-icon"
                        />
                        <span className="sr-only">Close</span>
                      </button>
                      <img
                        className="form-success__icon"
                        alt="Namaste"
                        src={Namaste}
                      />
                      <h5>Message received.</h5>
                      <p>Thank you, we will contact you shortly.</p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form__group">
                    <label htmlFor="name" className="form__label">
                      Name*
                    </label>
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      autocomplete="name"
                      className="form__input"
                      aria-required="true"
                    />
                    <ErrorMessage
                      name="name"
                      component="p"
                      className="form__unit-error"
                    />
                  </div>
                  <div className="form__group">
                    <label htmlFor="contactInfo" className="form__label">
                      Contact Info*
                    </label>
                    <Field
                      type="text"
                      name="contactInfo"
                      id="contactInfo"
                      className="form__input"
                      aria-required="true"
                      placeholder="e-mail or phone number"
                    />
                    <ErrorMessage
                      name="contactInfo"
                      component="p"
                      className="form__unit-error"
                    />
                  </div>
                  <div className="form__group col-md-wide">
                    <label htmlFor="message" className="form__label">
                      Message*
                    </label>
                    <Field
                      as="textarea"
                      rows="3"
                      name="message"
                      id="message"
                      className="form__input"
                      aria-required="true"
                    />
                    <ErrorMessage
                      name="message"
                      component="p"
                      className="form__unit-error"
                    />
                  </div>
                  <div className="hidden">
                    <label>Do you align with our policy</label>
                    <Field name="policy" type="checkbox"></Field>
                  </div>
                  <div className="button-wrap">
                    <button
                      className="button"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Send
                    </button>
                    <button className="link_text" onClick={toggleFormShown}>
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          ""
        )}
      </div>
    </li>
  )
}

ProfileCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  number: PropTypes.string,
}

ProfileCard.defaultProps = {
  number: "+91 956 775 2287",
}

export default ProfileCard
